/**
 * UI theme local storage key
 */
export const THEME_STORAGE_KEY = 'theme';

/**
 * quests_in_progress local storage key
 */
export const QUESTS_IN_PROGRESS_STORAGE_KEY = 'quests_in_progress';

/**
 * quests_in_review local storage key
 */
export const QUESTS_IN_REVIEW_STORAGE_KEY = 'quests_in_review';

/**
 * quests_completed local storage key
 */
export const QUESTS_COMPLETED_STORAGE_KEY = 'quests_completed';

/**
 * quests_rejected local storage key
 */
export const QUESTS_REJECTED_STORAGE_KEY = 'quests_rejected';

/**
 * quests_canceled local storage key
 */
export const QUESTS_CANCELED_STORAGE_KEY = 'quests_canceled';

/**
 * shop_location local storage key
 */
export const SHOP_LOCATION_STORAGE_KEY = 'shop_location';

/**
 * pincoins_balance local storage key
 */
export const PINCOINS_BALANCE_STORAGE_KEY = 'pincoins_balance';

/**
 * news page_category local storage key
 */
export const PAGE_CATEGORY_STORAGE_KEY = 'page_category';

/**
 * education library page local storage key
 */
export const PAGE_EDUCATION_LIBRARY_POLICY_STORAGE_KEY = 'page_education_library_policy';
